.notification-modal-container {
  .currency-modal {
    border-radius: 16px;
    width: 528px;
    position: absolute;
    top: 74px;
    left: 60%;
    z-index: 1005;
    border: 2px solid rgba(255, 255, 255, 0.5) !important;
    background: #a4a4a3 !important;
    backdrop-filter: blur(70px);
    .section-heading {
      display: flex;
      margin-top: 10px;
     padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .cancel-img {
        cursor: pointer;
      }
    }
    .modal-title {
      color: #fff;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      padding-left: 20px;
      line-height: 20px; /* 83.333% */
    }
    .unread-notification-text {
      margin-top: 8px;
      color: white;
      padding-left: 40px;
      margin-bottom: 20px;
    }
  }
  .all-notifications {
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 6px;
    border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
    background: var(--white-100, #fff);
    .notification-title {
      color: #000;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 133.333% */
    }
    .time-text {
      color: #4198ff;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
    }
  }
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .view-all-notifications {
    text-align: center;
    padding: 15px 0px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: #ff9024;
    backdrop-filter: blur(70px);
    color: white;
    cursor: pointer;
  }
}
