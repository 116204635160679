.search-field {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;

  .anticon {
    color: #333333;
  }

  .ant-input-affix-wrapper {
    height: 32px;
  }
}
